<template>
  <v-card elevation="20" class="auth-login">
    <div class="login-panel">
      <div class="login-title">
        <div class="login-main-title"><span>登</span><span>录</span></div>
        <div class="login-slogn-title">登录然后开始今天的学习</div>
      </div>
      <div class="login-form">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="loginForm.userName"
                  :rules="usernameRules"
                  clear-icon="fas fa-times"
                  label="用户名或手机号邮箱"
                  required
                  clearable
                  class="login-text"
                  color="#8ca0ff"
                  background-color="#eee"
                  filled
                  rounded
                  dense
                  @click:clear="zzzMid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="loginForm.passWord"
                  :rules="passwordRules"
                  clear-icon="fas fa-times"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                  label="您的密码"
                  @click:append="changeShowPass"
                  required
                  clearable
                  class="login-text"
                  color="#8ca0ff"
                  background-color="#eee"
                  filled
                  rounded
                  dense
                  @click:clear="zzzMid"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-btn
                  class="login-button"
                  :loading="loginLoading"
                  :disabled="loginLoading"
                  :color="loginButtonColor"
                  @click="doLogin"
                  @touchstart="zzzShort"
                  @touchend="zzzMid"
                >
                  立刻登录
                  <template v-slot:loader>
                    <span class="loading-status"
                      >正在登录
                      <span class="icon-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span></span
                    >
                  </template>
                </v-btn>
              </v-col>

              <v-col cols="12" md="12">
                <v-btn class="login-forget" color="#999" text @click="doForget"
                  >不小心忘记了密码？</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import $api from "@/api/api";
import { buttonVibrate } from "@/mixin/mixin";
import { deepClone } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "loginPanel",
  mixins: [buttonVibrate],
  computed: {
    ...mapGetters({
      appLoading: "appLoading",
    }),
  },
  data: () => ({
    loginLoading: false,
    loginButtonColor: "#4c62cc",
    valid: true,
    showPass: false,
    loginForm: {
      userName: "",
      passWord: "",
    },
    usernameRules: [
      (v) => !!v || "请输入用户名",
      (v) => (v && v.length >= 4) || "用户名不少于4个字符！",
      (v) => (v && v.length <= 20) || "用户名长度不能超过20个字符",
      (v) => /^[A-Za-z0-9]+$/.test(v) || "用户名只能是英文和数字！",
    ],
    passwordRules: [
      (v) => !!v || "请输入密码",
      (v) => (v && v.length >= 6) || "密码不少于6个字符！",
      (v) => (v && v.length <= 20) || "密码长度不能超过20个字符",
    ],
  }),
  methods: {
    changeShowPass() {
      this.showPass = !this.showPass;
      this.zzzShort();
    },
    doLogin() {
      if (this.$refs.form.validate()) {
        this.loginLoading = true;
        let postData = deepClone(this.loginForm);
        $api
          .doLogin(postData)
          .then((ret) => {
            let res = ret.data;
            if (res.code === 0) {
              let auth = res.data;
              this.$store.commit("doLongLogin", auth);
              this.$toast.success(res.msg);
              this.$store.dispatch("setAppLoading", {
                show: true,
                opacity: 0.98,
                theme: "#e3e8ff",
                color: "#4c62cc",
                time: 1500,
              });
              setTimeout(() => {
                this.$router.replace("/");
              }, 500);
              setTimeout(() => {
                this.$store.dispatch("setAppLoading", {
                  show: false,
                  opacity: 0.98,
                  theme: "#e3e8ff",
                  color: "#4c62cc",
                  time: 1500,
                });
              }, this.appLoading.time);
            } else {
              this.$toast.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loginLoading = false;
          });
      } else {
        this.zzzLong();
      }
    },
    doForget() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-icon {
  font-size: 1rem;
}
::v-deep .v-label {
  font-size: 0.85rem;
  letter-spacing: 0.15rem;
}
::v-deep .v-input--dense .v-label {
  top: 16px;
}

.auth-login {
  position: absolute;
  left: 0;
  bottom: -18%;
  opacity: 0.5;
  width: 100%;
  height: 72%;
  //min-height: 30rem;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 2rem 0 0 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  animation: slogn-motion 0.8s ease;
  -webkit-animation: slogn-motion 0.8s ease; /*Safari and Chrome*/
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  animation-fill-mode: forwards;
  --webkit-animation-fill-mode: forwards;
  @keyframes slogn-motion {
    from {
      bottom: -18%;
      opacity: 0.5;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @-webkit-keyframes slogn-motion /*Safari and Chrome*/ {
    from {
      bottom: -18%;
      opacity: 0.5;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .login-panel {
    position: relative;
    width: calc(100% - 3rem);
    min-height: calc(100% - 3rem);
    //background-color: #303030;
    border-radius: 2rem !important;

    .login-form {
      width: 100%;
      min-height: calc(100% - 14rem);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 0;
      padding: 0 0.3rem;
      .login-text {
        margin-top: -1.5rem;
        border-radius: 1rem !important;
      }
      .login-button {
        margin-top: 0.6rem;
        width: 100%;
        height: 2.7rem;
        color: #eeeeee;
        font-size: 0.95rem;
        font-weight: 500;
        border-radius: 1rem !important;
        letter-spacing: 0.5rem !important;
      }
    }
    .login-forget {
      width: 100%;
      height: 2.7rem;
      font-size: 0.75rem;
      letter-spacing: 0.15rem !important;
      text-align: center;
      border-radius: 1rem !important;
    }

    .login-title {
      letter-spacing: 0.2rem;
      margin-top: 2rem;
      text-align: center;
      height: 7rem;
      //@media (max-height: 667px) {
      //  margin-top: 0.5rem;
      //  margin-bottom: 1.5rem;
      //}
      .login-main-title {
        > span {
          margin: 0.5rem;
          font-size: 2rem;
          font-weight: 700;
        }
      }

      .login-slogn-title {
        font-size: 0.8rem;
        margin-top: 0.8rem;
        color: #707070;
        font-weight: 300;
      }
    }
  }
}
.loading-status {
  letter-spacing: 0.2rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
